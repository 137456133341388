import { useState } from 'react';
import './Calculator.css';
import { Button, Card, CardBody, CardHeader, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, ListGroup, ListGroupItem } from 'reactstrap';

export const Calculator = () => {

  const [energyUsage, setEnergyUsage] = useState(730000);
  const [energyRate, setEnergyRate] = useState(0.14);
  const [eastWest, setEastWest] = useState(30.0);
  const [northSouth, setNorthSouth] = useState(50.0);
  const [availableAcres, setAvailableAcres] = useState(12.0);
  const [towerCapacity, setTowerCapacity] = useState(25.0);
  const [price, setPrice] =  useState(2.5);

  const [energyUsageOld, setEnergyUsageOld] = useState(730000);
  const [energyRateOld, setEnergyRateOld] = useState(0.14);
  const [eastWestOld, setEastWestOld] = useState(30.0);
  const [northSouthOld, setNorthSouthOld] = useState(50.0);
  const [availableAcresOld, setAvailableAcresOld] = useState(12.0);
  const [towerCapacityOld, setTowerCapacityOld] = useState(25.0);
  const [priceOld, setPriceOld] =  useState(2.5);


  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setDropdownOpen2((prevState) => !prevState);

  const updateCalculations = () => {
    setEnergyUsageOld(energyUsage);
    setEnergyRateOld(energyRate);
    setEastWestOld(eastWest);
    setNorthSouthOld(northSouth);
    setAvailableAcresOld(availableAcres);
    setTowerCapacityOld(towerCapacity);
    setPriceOld(price);
  } 

  const degToRad = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi/180);
  }
  
  const getTowersPerAcre = () => {
    return (43560/((3*degToRad(eastWestOld)*40+60)*(3*degToRad(northSouthOld)*40+60)))*9
  }

  return <>
    <img src="logo_mixed_black.png" className='im-logo' alt="Janta Power Mid-sized Logo"/>
    <div className="calculator d-flex flex-row flex-wrap justify-content-center">
    <Card className='me-5 ms-5 mb-5 flex-grow-1'>
        <CardHeader className='title'>
          <CardTitle className='text-center h5'>
            Input Factors
          </CardTitle>
        </CardHeader>
        <CardBody className='text-start'>
          <Form>
            <FormGroup>
              <Label>
                Monthly Energy Usage (kWh)
              </Label>
              <Input id="monthlyEnergyUsage" name='monthlyUsage' placeholder='730000' onChange={e => setEnergyUsage(e.target.value)}/>
            </FormGroup>
            <FormGroup>
              <Label>
                Current Energy Rate ($)
              </Label>
              <Input id="currentEnergyRate" name='energyRate' placeholder='0.14' onChange={e => setEnergyRate(e.target.value)}/>
            </FormGroup>
            {/*<FormGroup>
              <Label>
                East and West Sun Angle
              </Label>
              <Input id="eastWestSun" name='eastWest' placeholder='30' onChange={e => setEastWest(e.target.value)}/>
            </FormGroup>
            <FormGroup>
              <Label>
                North and South Sun Angle
              </Label>
              <Input id="northSouthSun" name='northSouth' placeholder='50' onChange={e => setNorthSouth(e.target.value)}/>
            </FormGroup>*/}
            <FormGroup>
              <Label>
                Acres Available
              </Label>
              <Input id="acresAvailable" name='acres' placeholder='12' onChange={e => setAvailableAcres(e.target.value)}/>
            </FormGroup>
            <FormGroup>
              <Label>
                Capacity of Each Tower (KW)
              </Label>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='end'>
                <DropdownToggle caret className='ddown'>{towerCapacity} KW</DropdownToggle>
                <DropdownMenu className='ddown-menu'>
                  <DropdownItem className='ddown' onClick={() => {setTowerCapacity(25.0);}}>
                    25 KW
                  </DropdownItem>
                </DropdownMenu>
                <Button className='ddown float-end me-5' onClick={() => {updateCalculations()}}>Calculate</Button>
            </Dropdown>
            {/*<Input id="towerCapacity" name='towerCap' placeholder='25' onChange={e => setTowerCapacity(e.target.value)}/>*/}
            </FormGroup>
            {/*<FormGroup>
              <Label>
                Price Per Watt ($)
              </Label>
              <Dropdown isOpen={dropdownOpen2} toggle={toggle2} direction='end'>
                <DropdownToggle caret className='ddown'>${price}</DropdownToggle>
                <DropdownMenu className='ddown-menu'>
                  <DropdownItem className='ddown' onClick={() => {setPrice(1.5);}}>
                    1.5
                  </DropdownItem>
                  <DropdownItem className='ddown' onClick={() => {setPrice(2.0);}}>
                    2.0
                  </DropdownItem>
                  <DropdownItem className='ddown' onClick={() => {setPrice(2.5);}}>
                    2.5
                  </DropdownItem>
                  <DropdownItem className='ddown' onClick={() => {setPrice(3.0);}}>
                    3.0
                  </DropdownItem>
                </DropdownMenu>
                <Button className='ddown float-end me-5' onClick={() => {updateCalculations()}}>Calculate</Button>
            </Dropdown>
          </FormGroup>*/}
          </Form>
        </CardBody>
      </Card>
      <Card className='me-5 ms-5 mb-5 flex-grow-1'>
        <CardHeader className='title'>
          <CardTitle className='text-center h5'>
            Land Usage
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ListGroup className='text-start'>
            <ListGroupItem>
              Total Possible Capacity:  {(availableAcresOld*getTowersPerAcre()*towerCapacityOld).toLocaleString('en-US', {maximumFractionDigits: 2})} KW
            </ListGroupItem>
            <ListGroupItem>
              Capacity Per Acre:  {(towerCapacityOld*getTowersPerAcre()).toLocaleString('en-US', {maximumFractionDigits: 2})} KW
            </ListGroupItem>
            <ListGroupItem>
              Number of Possible Towers: {(availableAcresOld*getTowersPerAcre()).toLocaleString('en-US', {maximumFractionDigits: 2})}
            </ListGroupItem>
            <ListGroupItem>
              Land Required for 2D Solar: {((availableAcresOld*getTowersPerAcre()*towerCapacityOld)/1000 * 8.3).toLocaleString('en-US', {maximumFractionDigits: 2})} acres
            </ListGroupItem>
            <ListGroupItem>
              Land Conserved Over 2D Solar: {((availableAcresOld*getTowersPerAcre()*towerCapacityOld)/1000 * 8.3 - availableAcres).toLocaleString('en-US', {maximumFractionDigits: 2})} acres
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
      <Card className='me-5 ms-5 mb-5 flex-grow-1'>
        <CardHeader className='title'>
          <CardTitle className='text-center h5'>
            Cost Projections
          </CardTitle>
        </CardHeader>
        <CardBody>
        <ListGroup className='text-start'>
          <ListGroupItem>
            Total Project Cost: ${(((energyUsageOld/(7*25))/1000)*priceOld*1000000).toLocaleString('en-US', {maximumFractionDigits: 2})}
          </ListGroupItem>
          <ListGroupItem>
            Total Project Cost with REAP: ${(((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.5).toLocaleString('en-US', {maximumFractionDigits: 2})}
          </ListGroupItem>
          <ListGroupItem>
            Tax Credit (40%): ${(((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.4).toLocaleString('en-US', {maximumFractionDigits: 2})}
          </ListGroupItem>
          <ListGroupItem>
            Total Project Cost with all Incentives: ${((((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.5) - (((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.4)).toLocaleString('en-US', {maximumFractionDigits: 2})}
          </ListGroupItem>
          <ListGroupItem>
            Payback Period: {(((((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.5) - (((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.4))/(energyRateOld*energyUsageOld*12)).toLocaleString('en-US', {maximumFractionDigits: 2})} Years
          </ListGroupItem>
          <ListGroupItem>
            Nominal LCOE: ${(((((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.5) - (((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.4))/(energyUsageOld*12*20)).toLocaleString('en-US', {maximumFractionDigits: 3})}
          </ListGroupItem>
          <ListGroupItem>
            Current Monthly Energy Cost: ${(energyUsageOld*energyRateOld).toLocaleString('en-US', {maximumFractionDigits: 3})}
          </ListGroupItem>
          <ListGroupItem>
            Projected Monthly Energy Cost Based on Nominal LCOE: ${(energyUsageOld*(((((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.5) - (((energyUsageOld/(7*25))/1000)*priceOld*1000000*0.4))/(energyUsageOld*12*20))).toLocaleString('en-US', {maximumFractionDigits: 3})}
          </ListGroupItem>
          <ListGroupItem>
            Projected Monthly Energy Cost Based on Energy Rate: ${(0.0).toLocaleString('en-US', {maximumFractionDigits: 3, minimumFractionDigits: 1})}
          </ListGroupItem>
        </ListGroup>
        </CardBody>
      </Card>
    </div>
  </>;
};
