import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Calculator } from './Calculator';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Calculator />);
serviceWorkerRegistration.register();